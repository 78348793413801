import { PrependShop } from 'src/contexts/types'
import { axiosInstance } from 'src/utils/axios'
import { componentServiceBaseUrl } from 'src/config'
import { logging } from 'src/utils/logging'
import { PageConfig } from 'storefront-interpreter/src/config'

export type GetPageConfig = (id?: string) => Promise<PageConfig | undefined>
export type GetPageConfigApi = PrependShop<GetPageConfig>

// return a single page config given an ID.
export const getPageConfigApi: GetPageConfigApi = async (activeShop: string, id: string) => {
  // first, fetch our new format configs from the component service.
  let pageConfigs: PageConfig[] = []
  try {
    const url = `/v1/pages?ids=${id}&limit=1`

    const response =  await axiosInstance.get<PageConfig[]>(url, {
      baseURL: componentServiceBaseUrl,
      headers:{
        'Shop': activeShop,
      },
    })
    if (response?.data) {
      pageConfigs = response.data
    }
  } catch(error) {
    logging(error, { tags: { section: 'get page config api' } })
  }

  return pageConfigs[0]
}
