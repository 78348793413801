import React, { FC, useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Autocomplete } from '@mui/material'
import { Category } from 'src/contexts/types'
import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import useGenericContext from 'src/hooks/useGenericContext'
import { logging } from 'src/utils/logging'

const PREFIX = 'AsyncSelectCategories'

const classes = {
  listbox: `${PREFIX}-listbox`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.listbox}`]: {
    display: 'grid',
    padding: '0px !important',
    gridGap: '20px',
    textAlign: 'center',
    paddingBottom: '18px !important',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(auto-fill, 150px)',
  },
}))

interface AsyncSelectCategoriesProps {
  selected?: Category
  onChange: (e, value: Category | null) => void
  inputRef?: React.MutableRefObject<HTMLElement | null>
  previouslySelected?: string[]
  openWhenNotSelected?: boolean
  error?: boolean | undefined
  label?: string | undefined
}


const AsyncSelectCategories: FC<AsyncSelectCategoriesProps> = ({ selected, onChange, inputRef, previouslySelected, openWhenNotSelected, error, label }) => {
  const filterStringRef = useRef<HTMLElement | null>(null)

  const { searchCategories } = useGenericContext()
  const [searchedCategories, setSearchedCategories] = React.useState<Category[]>([])
  const [categorySearchString, setCategorySearchString] = React.useState<string>('')
  const [categorySearching, setCategorySearching] = React.useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const [offset, setOffset] = useState(0)
  const limit = 10

  useEffect(() => {
    if ((searchedCategories.length > 0) && !selected) {
      // NOTE: delay needed because there is no batching of hook dependencies so
      //       'items' come in while 'selected' is undefined, then 'selected' comes in
      let timerId
      if (openWhenNotSelected)
        timerId = setTimeout(() => setOpen(true), 200)

      return () => {
        clearTimeout(timerId)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedCategories, selected])

  useEffect(() => {
    async function fetchSomeCategories() {
      setCategorySearching(true)
      try {
        if (offset < 0) {
          setCategorySearching(false)
          return
        }
        const categoriesResponse = await searchCategories(categorySearchString, limit, offset)
        const categories = categoriesResponse || []
        if (offset > 0) {
          setSearchedCategories([...searchedCategories, ...categories])
        } else {
          setSearchedCategories(categories)
        }
        if (categories.length < limit) {
          setOffset(-1)
        }
        setCategorySearching(false)
      } catch (err) {
        setSearchedCategories([])
        setCategorySearching(false)
        logging(err, { tags: { section: 'get categories in component picker' } })
      }
    }
    const getDebouncedCategories = setTimeout(() => {
      fetchSomeCategories()
    }, categorySearchString ? 200 : 0)

    return () => clearTimeout(getDebouncedCategories)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySearchString, searchCategories, offset])

  useEffect(() => {
    if (open) setTimeout(() => filterStringRef?.current?.focus(), 0)
  }, [open, filterStringRef])

  const close = () => {
    filterStringRef.current?.blur()
    setOpen(false)
  }

  return (
    <Autocomplete
      id='search-categories'
      open={open}
      onClose={() => {
        // NOTE: setTimeout otherwise the blur doesn't work...
        setTimeout(() => {
          close()
        }, 0)
      }}
      classes={{ listbox: classes.listbox }}
      options={searchedCategories.filter((category) => !previouslySelected?.find(selectedCategoryId => selectedCategoryId === category.id) || category?.id === selected?.id)}
      filterSelectedOptions
      noOptionsText='No categories found'
      isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue.id}
      loading={categorySearching}
      clearIcon={categorySearchString ? undefined : <span />}
      getOptionLabel={category => category.title || ''}
      value={selected}
      inputValue={categorySearchString}
      onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e) return
        const targetValue = e?.target?.value || ''
        setCategorySearchString(targetValue)
      }}
      onChange={(e, value) => {
        onChange(e, value)
        close()
      }}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget
          if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 180 && offset >= 0 && !categorySearching) {
            setOffset(offset + limit)
          }
        },
        style: {
          textAlign: 'center',
          display: 'grid',
          gridGap: '24px',
          justifyContent: 'center',
          gridTemplateColumns: 'repeat(auto-fill, 150px)',
          padding: '0px !important',
          paddingBottom: '18px !important',
        },
      }}
      renderOption={(props, category) => {
        return (
          <li {...props} style={{
            justifyContent: 'center',
          }}>
            <span style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pointerEvents: 'none',
              height: '100%',
              padding: '3px',
              zIndex: 100,
            }}
            >
              <img
                style={{
                  objectFit: 'cover',
                  width: '100px',
                  height: '100px',
                }}
                loading='lazy'
                src={category.featuredImage[0]?.smallUrl}
                alt={category.featuredImage[0]?.altText || category.title}
              />
              <Box
                style={{
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  display: '-webkit-box',
                }}
              >
                <Typography variant='h5'
                  style={{
                    padding: '6px 0px',
                  }}
                >
                  {category.title}
                </Typography>
              </Box>
              {/* <Box
          style={{
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            marginTop: 'auto',
            overflow: 'hidden',
            display: '-webkit-box',
          }}
        >
          {stripHtml(category.description || '')}
        </Box> */}
            </span>
          </li>
        )
      }}
      renderInput={(params) => {
        return (
          <TextField
            onBlur={() => close()}
            onFocus={() => setOpen(true)}
            inputRef={(element) => {
              filterStringRef.current = element
              if (inputRef)
                inputRef.current = element
            }}
            fullWidth={true}
            // NOTE: not necessary because it's handled by the Autocomplete but react still throws a warning about an uncontrolled component
            value={categorySearchString}
            label={label || 'Search categories'}
            error={error}
            size='small' margin='dense' type='string' variant='outlined'
            inputProps={{
              ...params.inputProps,
              value: filterStringRef.current === document.activeElement ? categorySearchString : selected?.title || categorySearchString,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Root>
                  {categorySearching ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Root>
              ),
            }}
            InputLabelProps={params.InputLabelProps}
          />
        )
      }}
    />
  )
}

export default AsyncSelectCategories
