

import {
  Grid,
  Typography,

  Button,
  FormControl,
  TextField,
  Tooltip,
  Modal,
  Box,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {  useEffect, type FC, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import {  PowerReviewsThirdParty, ThirdParty, ThirdPartyName } from 'src/types/storeSettings'
import ThirdPartyRowBase, { ThirdPartyRowBaseProps } from './ThirdPartyRowBase'
import { ModalStyles } from './ThirdPartyIntegrationRows'

const PREFIX = 'PowerReviewsRow'

const classes = {
  formText: `${PREFIX}-formText`,
  linkModal: `${PREFIX}-linkModal`,
}

const ModalRoot = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.linkModal}`]: ModalStyles,
}))

interface Props {
  thirdParty: PowerReviewsThirdParty
  thirdPartiesLoading: boolean
  submitThirdParty: (e: React.MouseEvent<HTMLButtonElement>, tp: ThirdParty) => Promise<void>
}

const PowerReviewsRow: FC<Props> = ({thirdParty,thirdPartiesLoading, submitThirdParty}) => {
  const [openModal, setOpenModal] = useState(false)
  const [apiKey, setApiKey] = useState('')
  const [merchantId, setMerchantId] = useState('')

  // obtain our local form state
  useEffect(() => {
    setApiKey(thirdParty.config.apiKey)
    setMerchantId(thirdParty.config.merchantId)
  }, [thirdParty])

  const powerReviewsRowBaseProps: ThirdPartyRowBaseProps = {
    imageName: 'PowerReviewsIntegration',
    name: ThirdPartyName.PowerReviews,
    subtext: 'The Ratings & Reviews Specialists',
    thirdPartyExists: apiKey !== '' || merchantId !== '',
    configPreview: merchantId,
    handleButtonClick: (e) => setOpenModal(true),
  }

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <ThirdPartyRowBase {...powerReviewsRowBaseProps} />
      </Grid>
      <Modal
        open={openModal}
        onClose={(e) => setOpenModal(false)}
      >
        <ModalRoot>
          <Box className={classes.linkModal}>
            <Typography
              style={{
                color: '#546D78',
                fontSize: 13,
                textTransform: 'uppercase',
                fontWeight: 600,
                marginBottom: 7,
              }}
              component="h3"
            >
              POWER REVIEWS INTEGRATION
            </Typography>
            <Typography
              style={{
                color: '#000000',
                fontSize: 13,
              }}
              id="modal-modal-description">
              Update your Power Reviews Integration settings.
            </Typography>
            <div style={{ padding: 15 }} />
            <FormControl fullWidth>
              <Grid
                direction='column'
                container
                spacing={2}
              >
                <Grid item>
                  <Grid
                    direction='column'
                    container
                  >
                    <Grid item
                    >
                      <div className={classes.formText}>
                        <Tooltip placement='top' title='Klaviyo > Click on profile icon > Settings > API Keys > Public API Key'>
                          <InfoIcon style={{ marginBottom: '-5px', height: 20, width: 20, marginRight: '4px' }} />
                        </Tooltip>
                        {'Power Reviews API key'}
                      </div>
                    </Grid>
                    <Grid item
                      style={{
                        width: '100%',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl fullWidth>
                          <TextField
                            autoFocus
                            size="small"
                            fullWidth
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setApiKey(e.target.value.trim())
                            }}
                            value={apiKey}
                            aria-describedby="secondary-color"
                            type="text"
                            variant="outlined"
                          />
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>


                <Grid item>
                  <Grid
                    direction='column'
                    container
                  >
                    <Grid item>
                      <div className={classes.formText}>
                        <Tooltip placement='top' title='Klaviyo > Audience > Lists & Segments > Newsletter (Create it) > Settings > ID'>
                          <InfoIcon style={{ marginBottom: '-5px', height: 20, width: 20, marginRight: '4px' }} />
                        </Tooltip>
                        {'Power Reviews Merchant ID'}
                      </div>
                    </Grid>
                    <Grid item>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl fullWidth>
                          <TextField
                            size="small"
                            fullWidth
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setMerchantId(e.target.value.trim())
                            }}
                            value={merchantId}
                            aria-describedby="secondary-color"
                            type="text"
                            variant="outlined"
                          />
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Button
                color='primary'
                variant='contained'
                style={{
                  width: '100%',
                  marginTop: 24,
                }}
                disabled={thirdPartiesLoading}
                onClick={(e) =>{
                  const newThirdParty = {
                    ...thirdParty,
                    config: {
                      apiKey,
                      merchantId,
                    },
                  }
                  submitThirdParty(e, newThirdParty)
                  setOpenModal(false)
                } }
              >
                Submit and exit
                &nbsp;
              </Button>
              <Button
                color='primary'
                variant='text'
                style={{
                  width: '100%',
                  marginTop: 1,
                }}
                onClick={(e) => setOpenModal(false)}
              >
                Cancel
              </Button>

            </FormControl>
          </Box>
        </ModalRoot>
      </Modal>
    </div>
  )
}

export default PowerReviewsRow
