import { PrependShop } from '../types'
import { AxiosResponse } from 'axios'
import { logging } from 'src/utils/logging'
import { axiosInstance } from 'src/utils/axios'
import { analyticsServiceBaseUrl2 } from 'src/config'
import { SdxValue } from './getCampaignSummaryMetrics'

export interface PageMetrics {
  pageId: string
  componentOrderId: string
  bounceRate: SdxValue
  avgPageViews: SdxValue
}

export type GetPageMetrics = (storefrontId: string, componentOrderId: string, isBrand:boolean, startTimestamp: number, endTimestamp: number, pageMLOrderType: 'MANUAL' | 'OPTIMAL') => Promise<AxiosResponse<PageMetrics> | undefined>
export type GetPageMetricsApi = PrependShop<GetPageMetrics>
export const getPageMetricsApi: GetPageMetricsApi = async (activeShop: string, storefrontId: string, componentOrderId: string, isBrand:boolean, startTimestamp: number, endTimestamp: number, pageMLOrderType: 'MANUAL' | 'OPTIMAL') => {
  try {
    let url = '/v1/analytics/page-ml-metrics'
    url += `?pageid=${storefrontId}`
    url += `&startTimestamp=${startTimestamp}`
    url += `&endTimestamp=${endTimestamp}`
    url += `&componentOrderId=${componentOrderId}`
    url += `&pageMLOrderType=${pageMLOrderType}`
    if(isBrand) url += `&isBrand=1`

    const response = await axiosInstance.get<PageMetrics>(url, { baseURL: analyticsServiceBaseUrl2, headers: {
      shop: activeShop,
    } })
    return response

  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get page metrics api' } })
  }

}
