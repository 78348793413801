import clsx from 'clsx'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { PageConfig } from 'storefront-interpreter/src/config'
import { Typography, Button, Modal, Box, TextField, IconButton, Snackbar, Tooltip, FormControlLabel, Checkbox } from '@mui/material'
import { SocialChannelPicker, getUtmMediumFromSource } from 'src/components/SocialChannelPicker'
import { copyTextToClipboard, upsert } from 'src/utils/helpers'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import useGenericContext from 'src/hooks/useGenericContext'
import { temporaryUtm } from 'src/utils/getInitialPageConfig'
import {Ad as MetaAd} from 'src/contexts/types'

const PREFIX = 'LinkBuilderModal'

const classes = {
  linkModal: `${PREFIX}-linkModal`,
}

const Root = styled('span')((
  {
    theme,
  },
) => ({
  [`&.${classes.linkModal}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    maxWidth: 600,
    width: '100%',
    padding: '24px',
    borderRadius: '4px',
  },
}))

export type UTM = {
  id: string
  campaign: string
  source: string
  medium: string
}

const getBlankUTM = (): UTM => ({
  id: Math.random().toString(),
  campaign: '',
  source: '',
  medium: '',
})


export type LinkBuilderModalProps = {
  isOpen: boolean
  onSave: (pageConfig: PageConfig) => Promise<void>
  onClose: () => void
  pageConfig: PageConfig
}

const isUtmValid = (utm: UTM) => {
  return utm.campaign && utm.source && utm.medium
}

export const LinkBuilderModal: FC<LinkBuilderModalProps> = ({
  isOpen,
  onClose,
  onSave,
  pageConfig,
}) => {
  const theme = useTheme()
  const [copied, setCopied] = useState(false)
  const [utms, setUtms] = useState<UTM[]>([])
  const [selectedId, setSelectedId] = useState('')
  const [utmSourceErrorString, setUtmSourceErrorString] = useState('')
  const [utmChannelErrorString, setUtmChannelErrorString] = useState('')
  const [selectedAd, setSelectedAd] = useState<MetaAd | undefined>(undefined)
  const [shouldWriteUrl, setShouldWriteUrl] = useState(false)

  const { publishPageConfig, statusBasedPageConfigs, getPageConfig, getAd, postLinkToMeta } = useGenericContext()

  // // serialize list to localhost
  // const fetchUtms = useCallback((pageConfigId: string) => {
  //   return JSON.parse(sessionStorage.getItem(`utms-${pageConfigId}`) as any || '[]')
  // }, [])
  // const saveUtms = useCallback((pageConfigId: string, newUtms) => {
  //   sessionStorage.setItem(`utms-${pageConfigId}`, JSON.stringify(newUtms))
  // }, [])

  // serialize to pageConfig
  const fetchUtms = useCallback((pageConfigId: string) => {
    const cfg = statusBasedPageConfigs.find(c => c.id === pageConfigId)
    if (!cfg) {
      console.error('Could not find pageConfig with id', pageConfigId)
      return []
    }

    return [{
      id: pageConfigId,
      campaign: cfg.utmCampaign === temporaryUtm.utmCampaign ? '' : cfg.utmCampaign,
      source: cfg.utmSource === temporaryUtm.utmSource ? '' : cfg.utmSource,
      medium: cfg.utmMedium === temporaryUtm.utmMedium ? '' : cfg.utmMedium,
    }]
  }, [statusBasedPageConfigs])
  const saveUtms = useCallback(async (pageConfigId: string, newUtms) => {
    const cfg = await getPageConfig(pageConfigId)
    if (!cfg) {
      console.error('Could not find pageConfig with id', pageConfigId)
      return
    }
    const utm = newUtms[0]
    console.log({utm, statusBasedPageConfigs})
    cfg.utmCampaign = utm.campaign
    cfg.utmSource = utm.source
    cfg.utmMedium = utm.medium
    publishPageConfig(cfg)
  }, [publishPageConfig, statusBasedPageConfigs])

  const handleValidate = useCallback(async (shouldWrite: boolean, ad: MetaAd | undefined) => {
    const utm = utms[0]

    if (!isUtmValid(utm)) {
      if (!utm.source) setUtmSourceErrorString('Please select a Social Channel')
      if (!utm.campaign) setUtmChannelErrorString('Please enter a Campaign Name')
      return
    }

    const url = new URL(pageConfig.pageUrl)
    url.searchParams.set('utm_source', utm.source)
    url.searchParams.set('utm_medium', utm.medium)
    url.searchParams.set('utm_campaign', utm.campaign)
    copyTextToClipboard(url.toString())
    setCopied(true)
    saveUtms(pageConfig.id, utms)
    if (shouldWrite && ad !== undefined) {
      const res = await postLinkToMeta(ad.accountId, ad.externalId, url.toString())
      if (res === undefined || res?.status !== 200) {
        // TODO: what's the best way to handle this error?
        window.alert('failed to write simplicity url to Meta.')
      }
    }

    onClose()
  }, [pageConfig, utms, saveUtms, onClose, postLinkToMeta])

  useEffect(() => {
    if (!pageConfig.id) return
    setUtms(fetchUtms(pageConfig.id))
    setSelectedId('')
  }, [pageConfig])

  // fetch our meta ad if our page config uses one as its campaign image.
  useEffect(() => {
    if (pageConfig.campaignMedias === undefined || pageConfig.campaignMedias.length <=0 || pageConfig.campaignMedias[0].postType !== 'ad') return
    const adID = pageConfig.campaignMedias[0].postId
    const accountID = pageConfig.campaignMedias[0].postAccountId

    if (adID === undefined || accountID === undefined) return

    const getAdAsync = async() => {
      const response = await getAd(accountID, adID)
      response?.data && setSelectedAd(response.data)
    }
    getAdAsync()
  },[pageConfig, getAd])

  // useEffect(() => {
  //   if (!pageConfig.id) return
  //   // saveUtms(pageConfig.id, utms)
  // }, [utms, pageConfig])

  return (
    <Root>
      <Snackbar
        open={copied}
        onClose={() => setCopied(false)}
        autoHideDuration={900}
        message="Copied to clipboard"
      />

      <Modal
        open={isOpen}
        onClose={onClose}
        keepMounted
      >
        <Root className={classes.linkModal}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            maxHeight: '50%',
            overflow: 'hidden',
          }}
        >
          <Typography
            style={{
              color: '#546D78',
              fontSize: 13,
              fontWeight: 600,
              marginBottom: 7,
            }}
            component="h3"
          >
            UTM Links -&nbsp;{pageConfig.internalName}
          </Typography>

          <IconButton
            disableRipple
            sx={{
              m: 1,
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>

          <br/>

          <Box
            sx={{
              pt: 2,
              overflowY: 'auto',
            }}
          >
            {
              [...utms /*, getBlankUTM()*/].map((utm, j) => {
                const isLast = j === utms.length
                return (
                  <Box
                    key={utm.id}
                    sx={{
                      borderRadius: '5px',
                      backgroundColor: selectedId === utm.id ? theme.palette.brand.frenchBlue + '33' : 'transparent',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: 1,
                    }}
                  >
                    {/* <IconButton
                      disableRipple
                      sx={{
                        opacity: j === 0 ? 0 : 1,
                        // opacity: isLast ? 0 : 1,
                        mr: 1,
                      }}

                      onClick={() => {
                        if (j === 0) return
                        if (isLast) return
                        setUtms(utms.filter(u => u.id !== utm.id))
                        setSelectedId('')
                      }}
                    >
                      <CloseIcon />
                    </IconButton> */}

                    <SocialChannelPicker
                      error={!!utmSourceErrorString}
                      helperText={utmSourceErrorString || ' '}
                      onFocus={() => setSelectedId('')}
                      value={utm.source}
                      onChange={(newUtmSource) => {
                        const newUtm: UTM = {
                          ...utm,
                          source: newUtmSource,
                          medium: getUtmMediumFromSource(newUtmSource as any),
                        }
                        setUtms(upsert(utms, newUtm, 'id'))
                        setSelectedId('')
                        setUtmSourceErrorString('')
                      }}

                      inputRef={null}
                    />

                    <TextField
                      error={!!utmChannelErrorString}
                      helperText={utmChannelErrorString || ' '}
                      onFocus={(e) => {
                        if (isLast) return
                        setSelectedId('')
                      }}
                      required
                      size='small'
                      sx={{mx: 1, width: '100%'}}
                      label='Campaign name' /* MODAL */
                      inputRef={null}
                      margin='none' variant='outlined'
                      value={utm.campaign}
                      onChange={(e) => {
                        const newUtm: UTM = {
                          ...utm,
                          campaign: e.target.value,
                        }
                        setUtms(upsert(utms, newUtm, 'id'))
                        setSelectedId('')
                        setUtmChannelErrorString('')
                      }}
                    />

                    {/* <Tooltip title="Copy Link to Clipboard" placement='right'>
                      <IconButton
                        disableRipple
                        disabled={!isUtmValid(utm)}
                        sx={{
                          opacity: isLast ? 0 : 1,
                          mx: 1,
                        }}

                        onClick={() => {
                          const url = new URL(pageConfig.pageUrl)
                          url.searchParams.set('utm_source', utm.source)
                          url.searchParams.set('utm_medium', utm.medium)
                          url.searchParams.set('utm_campaign', utm.campaign)
                          copyTextToClipboard(url.toString())

                          setCopied(true)
                          if (isLast) return
                          setSelectedId(utm.id)
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip> */}
                  </Box>
                )
              })
            }

            {selectedAd?.canWriteUrl ? <Box
              sx={{
                backgroundColor:  'transparent' ,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '32px',
              }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shouldWriteUrl}
                    onChange={() => setShouldWriteUrl(!shouldWriteUrl)}
                    color="primary"
                  />
                }
                label={
                  selectedAd.links.length === 0 ?
                    <span>
                    Select to write this page's link to its selected ad.
                    Note that writeback is not supported for ads with info labels or ads with business folder creatives.
                    </span> :
                    <span>
                    Select to write this page's link to its selected ad. The ad's current link (
                      <a href={selectedAd?.links[0]} target="_blank" rel="noopener noreferrer">
                        { selectedAd?.links[0] }
                      </a>
                    ) will be overwritten by this action.
                    Note that writeback is not supported for ads with info labels or ads with business folder creatives.
                    </span>
                }
              />
            </Box>:''}

            <Button
              disabled={!!utmSourceErrorString || !!utmChannelErrorString}
              variant='contained'
              onClick={() => {
                setSelectedId('')
                handleValidate(shouldWriteUrl, selectedAd)
              }}
            >
              Save Link & Copy To Clipboard
            </Button>
          </Box>
        </Root>
      </Modal>

    </Root>
  )
}
